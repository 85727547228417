import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Contacts, RecentUsers, UserData } from '../data/users';

@Injectable()
export class UserService extends UserData {
  private time: Date = new Date();

  private usersEn = {
    nick: { name: 'Khaled Elrifaay', picture: 'assets/images/alan.png' },
    eva: { name: 'Ahmed Ismail', picture: 'assets/images/pp.png' },
    jack: { name: 'Aymen Salim', picture: 'assets/images/pp.png' },
    lee: { name: 'Hend Mohamed', picture: 'assets/images/pp.png' },
    alan: { name: 'Mai Ibrahim', picture: 'assets/images/pp.png' },
    kate: { name: 'Mazin Shokry', picture: 'assets/images/pp.png' },
  };
  private usersAr = {
    nick: { name: 'خالد الرفاعي', picture: 'assets/images/pp.png' },
    eva: { name: 'احمد اسماعيل', picture: 'assets/images/pp.png' },
    jack: { name: 'ايمن سليم', picture: 'assets/images/pp.png' },
    lee: { name: 'هند محمد', picture: 'assets/images/pp.png' },
    alan: { name: 'مي ابراهيم', picture: 'assets/images/pp.png' },
    kate: { name: 'مازن شكري', picture: 'assets/images/pp.png' },
  };
  private types = {
    mobile: 'mobile',
    home: 'home',
    work: 'work',
  };
  private typesAr = {
    mobile: 'هاتف',
    home: 'منزل',
    work: 'عمل',
  };
  private contactsEn: Contacts[] = [
    { user: this.usersEn.nick, type: this.types.mobile },
    { user: this.usersEn.eva, type: this.types.home },
    { user: this.usersEn.jack, type: this.types.mobile },
    { user: this.usersEn.lee, type: this.types.mobile },
    { user: this.usersEn.alan, type: this.types.home },
    { user: this.usersEn.kate, type: this.types.work },
  ];
  private contactsAr: Contacts[] = [
    { user: this.usersAr.nick, type: this.typesAr.mobile },
    { user: this.usersAr.eva, type: this.typesAr.home },
    { user: this.usersAr.jack, type: this.typesAr.mobile },
    { user: this.usersAr.lee, type: this.typesAr.mobile },
    { user: this.usersAr.alan, type: this.typesAr.home },
    { user: this.usersAr.kate, type: this.typesAr.work },
  ];
  private recentUsers: RecentUsers[] = [
    {
      user: this.usersEn.alan,
      type: this.types.home,
      time: this.time.setHours(21, 12),
    },
    {
      user: this.usersEn.eva,
      type: this.types.home,
      time: this.time.setHours(17, 45),
    },
    {
      user: this.usersEn.nick,
      type: this.types.mobile,
      time: this.time.setHours(5, 29),
    },
    {
      user: this.usersEn.lee,
      type: this.types.mobile,
      time: this.time.setHours(11, 24),
    },
    {
      user: this.usersEn.jack,
      type: this.types.mobile,
      time: this.time.setHours(10, 45),
    },
    {
      user: this.usersEn.kate,
      type: this.types.work,
      time: this.time.setHours(9, 42),
    },
    {
      user: this.usersEn.kate,
      type: this.types.work,
      time: this.time.setHours(9, 31),
    },
    {
      user: this.usersEn.jack,
      type: this.types.mobile,
      time: this.time.setHours(8, 0),
    },
  ];
  private recentUsersAr: RecentUsers[] = [
    {
      user: this.usersAr.alan,
      type: this.typesAr.home,
      time: this.time.setHours(21, 12),
    },
    {
      user: this.usersAr.eva,
      type: this.typesAr.home,
      time: this.time.setHours(17, 45),
    },
    {
      user: this.usersAr.nick,
      type: this.typesAr.mobile,
      time: this.time.setHours(5, 29),
    },
    {
      user: this.usersAr.lee,
      type: this.typesAr.mobile,
      time: this.time.setHours(11, 24),
    },
    {
      user: this.usersAr.jack,
      type: this.typesAr.mobile,
      time: this.time.setHours(10, 45),
    },
    {
      user: this.usersAr.kate,
      type: this.typesAr.work,
      time: this.time.setHours(9, 42),
    },
    {
      user: this.usersAr.kate,
      type: this.typesAr.work,
      time: this.time.setHours(9, 31),
    },
    {
      user: this.usersAr.jack,
      type: this.typesAr.mobile,
      time: this.time.setHours(8, 0),
    },
  ];

  getUsers(): Observable<any> {
    return observableOf(this.usersEn);
  }

  getContacts(lang): Observable<Contacts[]> {
    if (lang === 'en') {
      return observableOf(this.contactsEn);
    } else {
      return observableOf(this.contactsAr);
    }
  }

  getRecentUsers(lang): Observable<RecentUsers[]> {
    if (lang === 'en') {
      return observableOf(this.recentUsers);
    } else {
      return observableOf(this.recentUsersAr);
    }
  }
}
