import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ngx-dynamic-pagination',
  templateUrl: './dynamic-pagination.component.html',
  styleUrls: ['./dynamic-pagination.component.scss']
})
export class DynamicPaginationComponent implements OnInit  {
  @Input() currentPage:any
  @Input() totalPages:any
  @Output() rows = new EventEmitter<any>();
  rowsNumber:any = 10
  constructor() { }
  ngOnInit(): void {
  }

  changePage(event){
    this.rowsNumber = event
    event ? this.rows.emit(event) :this.rows.emit(10);
  }
}

