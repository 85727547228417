<div class="row">
  <div class="col-md-12">
    <nb-card>
      <nb-card-body>
        <div class="flex-centered col-xl-4 col-lg-6 col-md-8 col-sm-12">
          <h2 class="title">Page not found</h2>
          <!-- <small class="sub-title"
            >Page not found</small
          > -->
          <!-- <button
            nbButton
            fullWidth
            (click)="navigatoeToDashboard()"
            type="button"
            class="home-button"
          >
            Take me home
          </button> -->
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>
