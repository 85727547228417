import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../services/helpers/events.service';


@Component({
  selector: 'ngx-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {

  lang: any;
  constructor(
    private eventsService: EventsService,
  ) {}

  ngOnInit(): void {
    this.eventsService.changeLangObser.subscribe((res) => {
      this.lang = res;
    });
  }
}
