import { NbMenuItem } from "@nebular/theme";
const versionNumber = require("../../../../package.json").version;
const dashboard = "Dashboard";
const contracts = "Contracts";
const version = "version ";
const settings = "Settings";
const Requestmaintenance = "Request Maintenance";
const receipts="Receipts"
let english;

  export let PayerMenu: NbMenuItem[] = [
    {
      title: dashboard,
      icon: { icon: "home", pack: "home" },
      link: "/pages/dashboard/payer",
    },
    {
      title: Requestmaintenance,
      icon: { icon: "maintenance", pack: "maintenance" },
      link: "/pages/maintenance",
    },
    {
      title: contracts,
      icon: { icon: "contracts", pack: "contracts" },
      link: "/pages/contracts",
    },
    {
      title: receipts,
      icon: { icon: "receipts", pack: "receipts" },
      link: "/pages/receipts",
    },
    {
      title: english,
      data: "change-language",
      icon: { icon: "lang", pack: "lang" },
    },
    {
      title: version + versionNumber,
    },
  ];
