import { NbMenuItem } from "@nebular/theme";

const versionNumber = require("../../../../package.json").version;
const dashboard = "Dashboard";
const units = "Units";
const contracts = "Contracts";
const invoices = "Invoices";
const payers = "Payers";
const transfers = "Transfers";
const reports = "Reports";
const banks = "Banks";
const maintenance = "Maintenance";
const version = "version ";
const singleInvoice ="Single Invoice "
const groupInvoice ="Group Invoice "
const searchGroupInvoice ="Search Group Invoice "
const recurringInvoices="Recurring Invoices"
const receipts="Receipts"
let english;
const settings = "Settings";
export let LandLordMenu: NbMenuItem[] = [
  {
    title: dashboard,
    icon: { icon: "home", pack: "home" },
    link: "/pages/dashboard/super",
  },
  {
    title: units,
    icon: { icon: "unit", pack: "unit" },
    link: "/pages/units",
  },
  {
    title: contracts,
    icon: { icon: "contracts", pack: "contracts" },
    link: "/pages/contracts",
  },
  {
    title: invoices,
    icon: { icon: "invoice", pack: "invoice" },

    children:[
      {
        title: recurringInvoices,
       icon: { icon: "invoice", pack: "invoice" },
        link: "/pages/invoices",
      }
      ,{
        title:singleInvoice,
        icon:{icon:'singleInvoice',pack:'singleInvoice'},
        link:"/pages/single-invoice"
      }
      ,{
        title:groupInvoice,
        icon:{ icon: "contracts", pack: "contracts"},
        link:"/pages/group-invoice"
      }
    ]
  },
  {
    title: payers,
    icon: { icon: "ten", pack: "ten" },
    link: "/pages/payers",
  },
  {
    title: banks,
    link: "/pages/banks",
    icon: { icon: "banks", pack: "banks" },
  },
  {
    title: transfers,
    icon: { icon: "trans", pack: "trans" },
    link: "/pages/transfers",
  },
  {
    title: reports,
    icon: { icon: "report", pack: "report" },
    link: "/pages/reports",
  },
  {
    title: receipts,
    icon: { icon: "receipts", pack: "receipts" },
    link: "/pages/receipts",
  },
  {
    title: maintenance,
    icon: { icon: "maintenance", pack: "maintenance" },
    link: "/pages/maintenance",
  },
  {
    title: settings,
    icon: { icon: "Settings", pack: "Settings" },
    children: [
      {
        title: english,
        data: "change-language",
        icon: { icon: "lang", pack: "lang" },
      },
    ],
  },
  {
    title: version + versionNumber,
  },
];
