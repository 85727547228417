import { Component, OnInit, ViewChild } from "@angular/core";
import * as moment from "moment";
import { AppService } from "../../../../services/helpers/app.service";
import { EventsService } from "../../../../services/helpers/events.service";
import { saveAs } from "file-saver";
import { TranslateService } from "@ngx-translate/core";
import {
  LocalDataSource,
  Ng2SmartTableComponent,
  ServerDataSource,
} from "ng2-smart-table";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { DatePickerService } from "../../../../services/helpers/date-picker.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "ngx-download-report",
  templateUrl: "./download-report.component.html",
  styleUrls: ["./download-report.component.scss"],
})
export class DownloadReportComponent implements OnInit {
  currentDate = new Date();
  @ViewChild("table") table: Ng2SmartTableComponent;
  year = this.currentDate.getFullYear();
  month = this.currentDate.getMonth();
  dateFrom: any;
  dateTo: any;
  showExport: boolean = false;
  userId = localStorage.getItem("userId");
  report_name: any;
  fileName = "";
  today = moment(new Date()).format("yyyy-MM-DD");
  lang = localStorage.getItem("rental_lang");

  settings = {};
  tableData: LocalDataSource = new LocalDataSource();
  showTable: boolean = false;
  nodata = "No data found";
  rows: number = 50;
  smartTable: Ng2SmartTableComponent;
  showSpinner: boolean = false;
  disablePdf: boolean = false;
  data;

  // start translate words //
  // 1- unpaid report:
  payer_name = "Payer Name";
  payer_phone = "Payer Mobile";
  contract_date_from = "Contract Date From";
  contract_date_to = "Contract Date To";
  created_on = "Created On";
  updated_on = "Updated On";
  unit = "Unit";
  collection_of_units = "Collection Of Units";
  due_pending = "Due Pending";
  contract_name = "Contract Name";
  contract_type = "Contract Type";
  // 2- paid report:
  amount = "Amount";
  payment_method = "Payment Method";
  // 3- delay report:
  invoice_name = "Invoice Name";
  phone = "Phone";
  start_date = "Start Date";
  end_date = "End Date";
  // 4- open report:
  // all found
  // 5- expiring report:
  contract_amount = "Contract Amount";
  contract_status = "Contract Status";
  frequency = "Frequency";
  collection_date = "Collection Date";
  // 6- maintenance report:
  tenant_name = "Tenant Name";
  request_date = "Request Date";
  tenant_phone = "Tenant Phone";
  location = "Location";
  maintenance_type = "Maintenance Type";
  company_description = "Company Description";
  attachment = "attachment";
  viewed = "Viewed";
  actual_cost = "Actual Cost";
  date_viewed = "Date Viewed";
  auth_by = "Auth By";
  priority = "Priority";
  // 7- activity report:
  log_name = "Log Name";
  user_id = "User ID";
  user_type = "User Type";
  user_name = "User Name";
  ip = "IP";
  browser = "Browser";
  subject_type = "Subject Type";
  subject_id = "Subject ID";
  subject_name = "Subject Name";
  date = "Date";
  //  8- future and expired contracts:
  contract_id = "Contract ID"
  owner_name = 'Owner Name'
  region = 'Region'
  building_number = 'Building Number'
  unit_number = 'Unit Number'
  total_amount = 'Total Amount'
  // end translate words //

  constructor(
    private appService: AppService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    private http: HttpClient,
    private datePickerService: DatePickerService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.data = this.activatedRoute.snapshot.queryParams;
    // console.log(this.data); // name of report, which user chosen from reports page.
    this.eventsService.changeLangObser.subscribe((res) => {
      this.lang = res;
      this.translateService.get(this.nodata).subscribe((res) => {
        this.addition_settings.noDataMessage = res;
      });
    });
    this.report_name = this.data["report"] + " Report";
    if (this.report_name == "Audit Log Report") {
      this.disablePdf = true;
    }
    this.getDate();
  }

  toggleMenu() {
    document.getElementById("dropdownmenu")?.classList.toggle("show-drop-down");
  }

  getDate() {
    if (
      this.data["report"] == "Unpaid" ||
      this.data["report"] == "Paid" ||
      this.data["report"] == "Delay" ||
      this.data["report"] == "Opened" ||
      this.data["report"] == "Maintenance" ||
      this.data["report"] == "Receipts" ||
      this.data["report"] == "Payment Session" 
    ) {
      this.dateFrom = moment(new Date(this.year, this.month, 1)).format(
        "yyyy-MM-DD"
      );
      this.dateTo = moment(new Date(this.year, this.month + 1, 0)).format(
        "yyyy-MM-DD"
      );
    } else if (this.data["report"] == "Audit Log") {
      this.dateFrom = this.today;
      this.dateTo = this.today;
    }
  }

  search() {
    this.showSpinner = true;
    this.showTable = true;
    // this.showExport = true;
    this.getDataFromApi();
  }

  reset() {
    this.dateTo = "";
    this.dateFrom = "";
    this.search();
  }

  // ********** start get data ********** //
  getSlug() {
    let report = this.data["report"];
    let slug = "";
    switch (report) {
      case "Unpaid":
        slug = `unpaid_report`;
        this.mergeSettings(this.unpaid_invoices_settings);
        this.formatUnpaid();
        this.translateUnpaid();
        break;
      case "Paid":
        slug = `payment_report`;
        this.mergeSettings(this.paid_invoices_settings);
        this.formatPaid();
        this.translatePaid();
        break;
      case "Delay":
        slug = `delayed_invoices_report`;
        this.mergeSettings(this.delay_invoices_settings);
        this.formatDelay();
        this.translateDelay();
        break;
      case "Opened":
        slug = `opening_unpaied_invoices_report`;
        this.mergeSettings(this.open_invoices_settings);
        this.formatOpened();
        this.translateOpened();
        break;
      case "Contracts Expiring Soon":
        slug = `expired_soon_contract_report`;
        this.mergeSettings(this.contracts_expiring_soon_settings);
        this.formatExpiring();
        this.translateExpiring();
        break;
      case "Maintenance":
        slug = `maintenance_report`;
        this.mergeSettings(this.maintenance_settings);
        this.formatMaintenance();
        this.translateMaintenance();
        break;
      case "Audit Log":
        slug = `saveActivityLogs`;
        this.mergeSettings(this.activity_log_settings);
        this.formatActivity();
        this.translateActivity();
        break;
      case "Future Contracts":
        slug = `future_contracts_report`;
        this.mergeSettings(this.future_expired_contracts_settings);
        this.formatFutureExpiredContracts();
        this.translateFutureExpiredContracts();
        break;
      case "Expired Contracts":
        slug = `expired_contracts_report`;
        this.mergeSettings(this.future_expired_contracts_settings);
        this.formatFutureExpiredContracts();
        this.translateFutureExpiredContracts();
        break;
      case "Payment Session":
        slug = `payment_sessions_report`;
        this.mergeSettings(this.Payment_session_settings);
        this.formatPaymentsession();
        this.translatePaymentsession();
        break;
      case "Receipts":
        slug = `receipts_reports?user_id=${
          this.userId
        }&lang=${this.lang.toUpperCase()}&companyCheckAll=true&miscellaneous=true&view=true&date_from=${
          this.dateFrom
        }&date_to=${this.dateTo}&`;
        this.mergeSettings(this.receipts_settings);
        this.formateReceipts();
        this.translateReceipt();
      default:
        break;
    }
    return slug;
  }

  getDataFromApi() {
    let slug = this.getSlug();
    const IsReceipt = this.data["report"] == "Receipts";
    slug = IsReceipt
      ? slug
      : slug +
        `?company_id=${this.userId}&date_from=${this.dateFrom}&date_to=${this.dateTo}&view=true`;
    this.tableData = new ServerDataSource(this.http, {
      endPoint: `${environment.URL}${slug}`,
      dataKey: "data",
      pagerLimitKey: "items_per_page",
      pagerPageKey: "page",
      totalKey: "total",
    });
    this.checkForChanges();
  }

  checkForChanges() {
    this.tableData.onChanged().subscribe((changes) => {
      if (changes.action == "page") {
        this.datePickerService.changePage(changes.paging?.page);
        this.showSpinner = false;
      }
      if (changes.elements) {
        const dataLength = changes.elements.length;
        this.showSpinner = false;
        this.showExport = dataLength > 0 ? true : false;
        this.getSlug(); // to call format method after data coming from response.
      }
    });
  }
  // ********** end get data ********** //

  // ********** start columns settings and format data ********** //
  mergeSettings(chosenReport) {
    this.settings = Object.assign(this.addition_settings, chosenReport);
  }

  addition_settings = {
    pager: {
      display: true,
      perPage: this.rows,
    },
    actions: false,
    noDataMessage: this.nodata,
  };

  unpaid_invoices_settings = {
    columns: {
      payer_name: {
        title: "payer_name",
        type: "text",
        filter: false,
      },
      payer_phone: {
        title: "payer_phone",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      unit: {
        title: "unit",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      collection_of_units: {
        title: "collection_of_units",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      due_pending: {
        title: "due_pending",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      contract_name: {
        title: "contract_name",
        type: "text",
        filter: false,
      },
      contract_type: {
        title: "contract_type",
        type: "text",
        filter: false,
      },
      contract_date_from: {
        title: "contract_date_from",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      contract_date_to: {
        title: "contract_date_to",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      created_on: {
        title: "created_on",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
    },
  };
  formatUnpaid() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].payer_name = data[i]?.payer?.name || "-";
      data[i].payer_phone =
        data[i]?.payer?.tel1 || data[i]?.payer?.mobile || "-";
      data[i].unit = data[i]?.contract?.unit_id || "-";
      data[i].collection_of_units = data[i]?.amount_total || "-";
      data[i].due_pending = data[i]?.amount_remaining || "-";
      data[i].contract_name = data[i]?.contract?.contract_name || "-";
      data[i].contract_type = data[i]?.contract?.service_type?.name || "-";
      data[i].contract_date_from = data[i]?.date_from || "-";
      data[i].contract_date_to = data[i]?.date_to || "-";
      data[i].created_on = data[i]?.created_on || "-";
    }
  }

  paid_invoices_settings = {
    columns: {
      payer_name: {
        title: "payer_name",
        type: "text",
        filter: false,
      },
      payer_phone: {
        title: "payer_phone",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      unit: {
        title: "unit",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      contract_name: {
        title: "contract_name",
        type: "text",
        filter: false,
      },
      contract_type: {
        title: "contract_type",
        type: "text",
        filter: false,
      },
      amount: {
        title: "amount",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      payment_method: {
        title: "payment_method",
        type: "text",
        filter: false,
      },
      created_on: {
        title: "created_on",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      updated_on: {
        title: "updated_on",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
    },
  };
  formatPaid() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].payer_name = data[i]?.payer?.name || "-";
      data[i].payer_phone =
        data[i]?.payer?.tel1 || data[i]?.payer?.mobile || "-";
      data[i].unit = data[i]?.contract?.unit_id || "-";
      data[i].amount = data[i]?.amount_total || "-";
      data[i].payment_method =
        data[i]?.receipts[0]?.payment_method?.name || "-";
      data[i].contract_name = data[i]?.contract?.contract_name || "-";
      data[i].contract_type = data[i]?.contract?.service_type?.name || "-";
      data[i].created_on = data[i]?.created_on || "-";
      data[i].updated_on = data[i]?.updated_on || "-";
    }
  }

  delay_invoices_settings = {
    columns: {
      invoice_name: {
        title: "invoice_name",
        type: "text",
        filter: false,
      },
      phone: {
        title: "phone",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      amount: {
        title: "amount",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      start_date: {
        title: "start_date",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      end_date: {
        title: "end_date",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      created_on: {
        title: "created_on",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
    },
  };
  formatDelay() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].invoice_name = data[i]?.name || "-";
      data[i].phone = data[i]?.payer?.tel1 || data[i]?.payer?.mobile || "-";
      data[i].amount = data[i]?.amount_total || "-";
      data[i].start_date = data[i]?.date_from || "-";
      data[i].end_date = data[i]?.date_to || "-";
      data[i].created_on = data[i]?.created_on || "-";
    }
  }

  open_invoices_settings = {
    columns: {
      invoice_name: {
        title: "invoice_name",
        type: "text",
        filter: false,
      },
      payer_name: {
        title: "payer_name",
        type: "text",
        filter: false,
      },
      payer_phone: {
        title: "payer_phone",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      amount: {
        title: "amount",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      start_date: {
        title: "start_date",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      end_date: {
        title: "end_date",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      created_on: {
        title: "created_on",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      updated_on: {
        title: "updated_on",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
    },
  };
  formatOpened() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].invoice_name = data[i]?.name || "-";
      data[i].payer_name = data[i]?.payer?.name || "-";
      data[i].payer_phone =
        data[i]?.payer?.tel1 || data[i]?.payer?.mobile || "-";
      data[i].amount = data[i]?.amount_total || "-";
      data[i].start_date = data[i]?.date_from || "-";
      data[i].end_date = data[i]?.date_to || "-";
      data[i].created_on = data[i]?.created_on || "-";
      data[i].updated_on = data[i]?.updated_on || "-";
    }
  }

  contracts_expiring_soon_settings = {
    columns: {
      contract_name: {
        title: "contract_name",
        type: "text",
        filter: false,
      },
      payer_name: {
        title: "payer_name",
        type: "text",
        filter: false,
      },
      contract_amount: {
        title: "contract_amount",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      contract_status: {
        title: "contract_status",
        type: "text",
        filter: false,
      },
      frequency: {
        title: "frequency",
        type: "text",
        filter: false,
        valuePrepareFunction: (data) => {
          if (data == "1") {
            data = "Daily";
          } else if (data == "2") {
            data = "Weekly";
          } else if (data == "3") {
            data = "Monthly";
          } else if (data == "4") {
            data = "Quarterly";
          } else if (data == "5") {
            data = "Semi-annual";
          } else if (data == "6") {
            data = "Annual";
          }
          return data;
        },
      },
      start_date: {
        title: "start_date",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      end_date: {
        title: "end_date",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      collection_date: {
        title: "collection_date",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      created_on: {
        title: "created_on",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
    },
  };
  formatExpiring() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].contract_name = data[i]?.contract_name || "-";
      data[i].payer_name = data[i]?.secondparty?.name || "-";
      data[i].contract_amount = data[i]?.amount || "-";
      data[i].contract_status = data[i]?.status || "-";
      data[i].frequency = data[i]?.frequncy || "-";
      data[i].start_date = data[i]?.start_date || "-";
      data[i].end_date = data[i]?.end_date || "-";
      data[i].collection_date = data[i]?.start_collection_date || "-";
      data[i].created_on = data[i]?.created_on || "-";
    }
  }

  maintenance_settings = {
    columns: {
      contract_name: {
        title: "contract_name",
        type: "text",
        filter: false,
      },
      tenant_name: {
        title: "tenant_name",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="max-width">${data}</span>`;
          }
        },
      },
      request_date: {
        title: "request_date",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      tenant_phone: {
        title: "tenant_phone",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      location: {
        title: "location",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="max-width">${data}</span>`;
          }
        },
      },
      maintenance_type: {
        title: "maintenance_type",
        type: "text",
        filter: false,
      },
      company_description: {
        title: "company_description",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="max-width">${data}</span>`;
          }
        },
      },
      attachment: {
        title: "attachment",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<a href="${data}" target="_blank" download> Show </a>`;
          }
        },
      },
      viewed: {
        title: "viewed",
        type: "html",
        filter: false,
        valuePrepareFunction: (data: number) => {
          if (data === 0) {
            return `<span class="text-center">not viewed</span>`;
          } else if (data === 1) {
            return `<span class="text-center">viewed</span>`;
          } else {
            return `<span class="text-center">-</span>`;
          }
        },
      },
      date_viewed: {
        title: "date_viewed",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
    },
  };
  formatMaintenance() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].contract_name = data[i]?.unit?.contracts[0]?.contract_name || "-";
      data[i].tenant_name = data[i]?.payer?.name || "-";
      data[i].request_date = data[i]?.created_on || "-";
      data[i].tenant_phone =
        data[i]?.payer?.tel1 || data[i]?.payer?.mobile || "-";
      data[i].location = data[i]?.unit?.address?.name || "-";
      data[i].maintenance_type = data[i]?.name || "-";
      data[i].company_description = data[i]?.description || "-";
      data[i].attachment = data[i]?.image || "-";
      data[i].viewed = data[i]?.read;
      data[i].date_viewed = data[i]?.updated_on || "-";
    }
  }

  receipts_settings = {
    columns: {
      receipt_number: {
        title: "Receipt Number",
        type: "string",
        filter: false,
      },
      payer_name: {
        title: "Payer Name",
        type: "string",
        filter: false,
      },
      payer_mobile: {
        title: "Payer Mobile",
        type: "string",
        filter: false,
      },
      unit_number: {
        title: "Unit Number",
        type: "string",
        filter: false,
      },
      building_name: {
        title: "Building Name",
        type: "string",
        filter: false,
      },
      region: {
        title: "Region",
        type: "string",
        filter: false,
      },
      payment_date: {
        title: "Payment Date",
        type: "string",
        filter: false,
      },
      payment_method: {
        title: "Payment Method",
        type: "string",
        filter: false,
      },
      duration: {
        title: "Duration",
        type: "string",
        filter: false,
      },
      receipt_type: {
        title: "Receipt Type",
        type: "string",
        filter: false,
      },
      total: {
        title: "Total",
        type: "string",
        filter: false,
      },
      invoice_count: {
        title: "Invoice Count",
        type: "string",
        filter: false,
      },
    },
  };
  formateReceipts() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].payer_name = data[i]?.invoices.payer?.name || "-";
      data[i].payer_mobile =
        data[i]?.invoices.payer?.tel1 || data[i]?.invoices.payer?.mobile || "-";
      data[i].unit_number = data[i]?.invoices?.contract?.unit?.unit_no || "-";
      data[i].total = data[i]?.amount_total || "-";
      data[i].building_name = data[i]?.invoices?.contract?.unit?.building?.name;
      data[i].region = data[i]?.invoices?.contract?.unit?.building?.region;
      data[i].payment_method = data[i]?.payment_method?.name || "-";
      data[i].payment_date = data[i]?.date_of_payment || "-";
      data[i].duration = `${this.translateService.instant("From")}  ${
        data[i]?.invoices?.date_from
      }  ${this.translateService.instant("To")}  ${data[i]?.invoices?.date_to}`;
      data[i].invoice_count = 1;
      data[i].receipt_type = data[i]?.receipt_type?.name;
      data[i].receipt_number = data[i].id;
    }
  }

  activity_log_settings = {
    columns: {
      date: {
        title: "date",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      user_id: {
        title: "user_id",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      user_type: {
        title: "user_type",
        type: "text",
        filter: false,
      },
      user_name: {
        title: "user_name",
        type: "text",
        filter: false,
      },
      subject_id: {
        title: "subject_id",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      log_name: {
        title: "log_name",
        type: "text",
        filter: false,
      },
      subject_type: {
        title: "subject_type",
        type: "text",
        filter: false,
      },
      subject_name: {
        title: "subject_name",
        type: "text",
        filter: false,
      },
      ip: {
        title: "ip",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      browser: {
        title: "browser",
        type: "text",
        filter: false,
      },
    },
  };
  formatActivity() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].log_name = data[i]?.log_name || "-";
      data[i].user_id = data[i]?.causer_id || "-";
      data[i].user_type = data[i]?.causer_type || "-";
      let desc = data[i]?.description?.split(",");
      data[i].user_name = this.formatText(desc[1]);
      data[i].ip = this.formatText(desc[2]);
      data[i].browser = this.formatText(desc[3]);
      data[i].subject_type = data[i]?.subject_type || "-";
      data[i].subject_id = data[i]?.subject_id || "-";
      data[i].subject_name = data[i]?.subject_name || "-";
      data[i].date = data[i]?.created_at || "-";
    }
  }

  formatText(text) {
    const regex = /\(([^)]+)\)/;
    const matches = text.match(regex);
    if (matches) {
      return matches[1];
    } else {
      return "-";
    }
  }

  future_expired_contracts_settings = {
    columns: {
      contract_name: {
        title: "contract_name",
        type: "text",
        filter: false,
      },
      payer_name: {
        title: "payer_name",
        type: "text",
        filter: false,
      },
      owner_name: {
        title: "owner_name",
        type: "text",
        filter: false,
      },
      location: {
        title: "location",
        type: "text",
        filter: false,
      },
      contract_type: {
        title: "contract_type",
        type: "text",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else if (data == "14")  {
            return this.translateService.instant("Rent");
          } else if (data == "15")  {
            return this.translateService.instant("maintenance");
          }
        },
      },
      end_date: {
        title: "end_date",
        type: "text",
        filter: false,
      },
      total_amount: {
        title: "total_amount",
        type: "text",
        filter: false,
      },
    }
  }
  formatFutureExpiredContracts() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].contract_name = data[i]?.contract_name || "-";
      data[i].contract_type = data[i]?.contract_type_id;
      data[i].payer_name = data[i]?.secondparty?.name || "-";
      data[i].owner_name = data[i]?.unit?.owner[0]?.name || "-";
      data[i].location = data[i]?.unit?.address?.name || "-";
      data[i].end_date = data[i]?.end_date || "-";
      data[i].total_amount = data[i]?.amount || "-";
    }
  }



  Payment_session_settings = {
    columns: {
      id: {
        title: "Session ID",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      cbk_track_id: {
        title: "Cbk Track ID",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      payment_id: {
        title: "Payment ID",
        type: "html",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return `<span class="isNumber">${data}</span>`;
          }
        },
      },
      created_at: {
        title: "Date",
        type: "date",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return new Date(data).toLocaleDateString();
          }
        },
      },
      payer_name: {
        title: "Payer Name",
        type: "text",
        filter: false,
        valuePrepareFunction: (data) => {
          console.log(`data: ${data}`);
          if (!data) {
            return "-";
          } else {
            return data;
          }
        },
      },
      gateway: {
        title: "Gateway",
        type: "text",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return data;
          }
        },
      },
      invoices_count: {
        title: "Invoices Count",
        type: "text",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return data?.length;
          }
        },
      },
      status: {
        title: "Status",
        type: "text",
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return "-";
          } else {
            return data;
          }
        },
      },
  }
}
  formatPaymentsession() {
    let data = this.tableData["data"];
    for (let i = 0; i < data.length; i++) {
      data[i].id = data[i]?.id || "-";
      data[i].cbk_track_id = data[i]?.cbk_track_id || "-";
      data[i].payment_id = data[i]?.payment_id || "-";
      data[i].created_at =  data[i]?.created_at || "-";
      data[i].payer_name =   data[i]?.invoices?.[0]?.payer?.name || "-";
      data[i].gateway = data[i]?.gateway || "-";
      data[i].invoices_count = data[i]?.invoices_count || "-";
      data[i].status = data[i]?.status || "-";
    }
  }

  // ********** end columns settings and format data ********** //

  // ********** start translate reports ********** //
  translateUnpaid() {
    this.eventsService.changeLangObser.subscribe((res) => {
      this.translateService.get(this.payer_name).subscribe((res) => {
        this.unpaid_invoices_settings.columns.payer_name.title = res;
      });
      this.translateService.get(this.payer_phone).subscribe((res) => {
        this.unpaid_invoices_settings.columns.payer_phone.title = res;
      });
      this.translateService.get(this.contract_date_from).subscribe((res) => {
        this.unpaid_invoices_settings.columns.contract_date_from.title = res;
      });
      this.translateService.get(this.contract_date_to).subscribe((res) => {
        this.unpaid_invoices_settings.columns.contract_date_to.title = res;
      });
      this.translateService.get(this.created_on).subscribe((res) => {
        this.unpaid_invoices_settings.columns.created_on.title = res;
      });
      this.translateService.get(this.unit).subscribe((res) => {
        this.unpaid_invoices_settings.columns.unit.title = res;
      });
      this.translateService.get(this.collection_of_units).subscribe((res) => {
        this.unpaid_invoices_settings.columns.collection_of_units.title = res;
      });
      this.translateService.get(this.due_pending).subscribe((res) => {
        this.unpaid_invoices_settings.columns.due_pending.title = res;
      });
      this.translateService.get(this.contract_name).subscribe((res) => {
        this.unpaid_invoices_settings.columns.contract_name.title = res;
      });
      this.translateService.get(this.contract_type).subscribe((res) => {
        this.unpaid_invoices_settings.columns.contract_type.title = res;
      });
    });
  }

  translatePaid() {
    this.translateService.get(this.payer_name).subscribe((res) => {
      this.paid_invoices_settings.columns.payer_name.title = res;
    });
    this.translateService.get(this.payer_phone).subscribe((res) => {
      this.paid_invoices_settings.columns.payer_phone.title = res;
    });
    this.translateService.get(this.unit).subscribe((res) => {
      this.paid_invoices_settings.columns.unit.title = res;
    });
    this.translateService.get(this.contract_name).subscribe((res) => {
      this.paid_invoices_settings.columns.contract_name.title = res;
    });
    this.translateService.get(this.contract_type).subscribe((res) => {
      this.paid_invoices_settings.columns.contract_type.title = res;
    });
    this.translateService.get(this.amount).subscribe((res) => {
      this.paid_invoices_settings.columns.amount.title = res;
    });
    this.translateService.get(this.payment_method).subscribe((res) => {
      this.paid_invoices_settings.columns.payment_method.title = res;
    });
    this.translateService.get(this.created_on).subscribe((res) => {
      this.paid_invoices_settings.columns.created_on.title = res;
    });
    this.translateService.get(this.updated_on).subscribe((res) => {
      this.paid_invoices_settings.columns.updated_on.title = res;
    });
  }

  translateDelay() {
    this.translateService.get(this.invoice_name).subscribe((res) => {
      this.delay_invoices_settings.columns.invoice_name.title = res;
    });
    this.translateService.get(this.phone).subscribe((res) => {
      this.delay_invoices_settings.columns.phone.title = res;
    });
    this.translateService.get(this.amount).subscribe((res) => {
      this.delay_invoices_settings.columns.amount.title = res;
    });
    this.translateService.get(this.start_date).subscribe((res) => {
      this.delay_invoices_settings.columns.start_date.title = res;
    });
    this.translateService.get(this.end_date).subscribe((res) => {
      this.delay_invoices_settings.columns.end_date.title = res;
    });
    this.translateService.get(this.created_on).subscribe((res) => {
      this.delay_invoices_settings.columns.created_on.title = res;
    });
  }

  translateOpened() {
    this.translateService.get(this.invoice_name).subscribe((res) => {
      this.open_invoices_settings.columns.invoice_name.title = res;
    });
    this.translateService.get(this.payer_name).subscribe((res) => {
      this.open_invoices_settings.columns.payer_name.title = res;
    });
    this.translateService.get(this.payer_phone).subscribe((res) => {
      this.open_invoices_settings.columns.payer_phone.title = res;
    });
    this.translateService.get(this.amount).subscribe((res) => {
      this.open_invoices_settings.columns.amount.title = res;
    });
    this.translateService.get(this.start_date).subscribe((res) => {
      this.open_invoices_settings.columns.start_date.title = res;
    });
    this.translateService.get(this.end_date).subscribe((res) => {
      this.open_invoices_settings.columns.end_date.title = res;
    });
    this.translateService.get(this.created_on).subscribe((res) => {
      this.open_invoices_settings.columns.created_on.title = res;
    });
    this.translateService.get(this.updated_on).subscribe((res) => {
      this.open_invoices_settings.columns.updated_on.title = res;
    });
  }

  translateExpiring() {
    this.translateService.get(this.contract_name).subscribe((res) => {
      this.contracts_expiring_soon_settings.columns.contract_name.title = res;
    });
    this.translateService.get(this.payer_name).subscribe((res) => {
      this.contracts_expiring_soon_settings.columns.payer_name.title = res;
    });
    this.translateService.get(this.contract_amount).subscribe((res) => {
      this.contracts_expiring_soon_settings.columns.contract_amount.title = res;
    });
    this.translateService.get(this.contract_status).subscribe((res) => {
      this.contracts_expiring_soon_settings.columns.contract_status.title = res;
    });
    this.translateService.get(this.frequency).subscribe((res) => {
      this.contracts_expiring_soon_settings.columns.frequency.title = res;
    });
    this.translateService.get(this.start_date).subscribe((res) => {
      this.contracts_expiring_soon_settings.columns.start_date.title = res;
    });
    this.translateService.get(this.end_date).subscribe((res) => {
      this.contracts_expiring_soon_settings.columns.end_date.title = res;
    });
    this.translateService.get(this.collection_date).subscribe((res) => {
      this.contracts_expiring_soon_settings.columns.collection_date.title = res;
    });
    this.translateService.get(this.created_on).subscribe((res) => {
      this.contracts_expiring_soon_settings.columns.created_on.title = res;
    });
  }

  translateMaintenance() {
    this.translateService.get(this.contract_name).subscribe((res) => {
      this.maintenance_settings.columns.contract_name.title = res;
    });
    this.translateService.get(this.tenant_name).subscribe((res) => {
      this.maintenance_settings.columns.tenant_name.title = res;
    });
    this.translateService.get(this.request_date).subscribe((res) => {
      this.maintenance_settings.columns.request_date.title = res;
    });
    this.translateService.get(this.tenant_phone).subscribe((res) => {
      this.maintenance_settings.columns.tenant_phone.title = res;
    });
    this.translateService.get(this.location).subscribe((res) => {
      this.maintenance_settings.columns.location.title = res;
    });
    this.translateService.get(this.maintenance_type).subscribe((res) => {
      this.maintenance_settings.columns.maintenance_type.title = res;
    });
    this.translateService.get(this.company_description).subscribe((res) => {
      this.maintenance_settings.columns.company_description.title = res;
    });
    this.translateService.get(this.attachment).subscribe((res) => {
      this.maintenance_settings.columns.attachment.title = res;
    });
    this.translateService.get(this.viewed).subscribe((res) => {
      this.maintenance_settings.columns.viewed.title = res;
    });
    this.translateService.get(this.date_viewed).subscribe((res) => {
      this.maintenance_settings.columns.date_viewed.title = res;
    });
  }

  translateActivity() {
    this.translateService.get(this.log_name).subscribe((res) => {
      this.activity_log_settings.columns.log_name.title = res;
    });
    this.translateService.get(this.user_id).subscribe((res) => {
      this.activity_log_settings.columns.user_id.title = res;
    });
    this.translateService.get(this.user_type).subscribe((res) => {
      this.activity_log_settings.columns.user_type.title = res;
    });
    this.translateService.get(this.user_name).subscribe((res) => {
      this.activity_log_settings.columns.user_name.title = res;
    });
    this.translateService.get(this.ip).subscribe((res) => {
      this.activity_log_settings.columns.ip.title = res;
    });
    this.translateService.get(this.browser).subscribe((res) => {
      this.activity_log_settings.columns.browser.title = res;
    });
    this.translateService.get(this.subject_type).subscribe((res) => {
      this.activity_log_settings.columns.subject_type.title = res;
    });
    this.translateService.get(this.subject_id).subscribe((res) => {
      this.activity_log_settings.columns.subject_id.title = res;
    });
    this.translateService.get(this.subject_name).subscribe((res) => {
      this.activity_log_settings.columns.subject_name.title = res;
    });
    this.translateService.get(this.date).subscribe((res) => {
      this.activity_log_settings.columns.date.title = res;
    });
  }

  translateReceipt() {
    this.translateService.get("Receipt Number").subscribe((res) => {
      this.receipts_settings.columns.receipt_number.title = res;
    });
    this.translateService.get(this.payer_name).subscribe((res) => {
      this.receipts_settings.columns.payer_name.title = res;
    });
    this.translateService.get(this.payer_phone).subscribe((res) => {
      this.receipts_settings.columns.payer_mobile.title = res;
    });
    this.translateService.get("Unit Number").subscribe((res) => {
      this.receipts_settings.columns.unit_number.title = res;
    });
    this.translateService.get("Building Name").subscribe((res) => {
      this.receipts_settings.columns.building_name.title = res;
    });
    this.translateService.get("Region").subscribe((res) => {
      this.receipts_settings.columns.region.title = res;
    });
    this.translateService.get(this.payment_method).subscribe((res) => {
      this.receipts_settings.columns.payment_method.title = res;
    });
    this.translateService.get("Payment Date").subscribe((res) => {
      this.receipts_settings.columns.payment_date.title = res;
    });
    this.translateService.get("Duration").subscribe((res) => {
      this.receipts_settings.columns.duration.title = res;
    });
    this.translateService.get("Receipt Type").subscribe((res) => {
      this.receipts_settings.columns.receipt_type.title = res;
    });
    this.translateService.get("Total").subscribe((res) => {
      this.receipts_settings.columns.total.title = res;
    });
    this.translateService.get("Invoice Count").subscribe((res) => {
      this.receipts_settings.columns.invoice_count.title = res;
    });
  }
  translatePaymentsession(){}
  translateFutureExpiredContracts() {
    this.translateService.get("Owner Name").subscribe((res) => {
      this.future_expired_contracts_settings.columns.owner_name.title = res;
    });
    this.translateService.get("Location").subscribe((res) => {
      this.future_expired_contracts_settings.columns.location.title = res;
    });
    this.translateService.get("Total Amount").subscribe((res) => {
      this.future_expired_contracts_settings.columns.total_amount.title = res;
    });
    this.translateService.get("Contract Name").subscribe((res) => {
      this.future_expired_contracts_settings.columns.contract_name.title = res;
    });
    this.translateService.get("Contract Type").subscribe((res) => {
      this.future_expired_contracts_settings.columns.contract_type.title = res;
    });
    this.translateService.get("Payer Name").subscribe((res) => {
      this.future_expired_contracts_settings.columns.payer_name.title = res;
    });
    this.translateService.get("End Date").subscribe((res) => {
      this.future_expired_contracts_settings.columns.end_date.title = res;
    });
  }
  // ********** end translate reports ********** //

  // ********** start download reports ********** //
  downloadExcel() {
    let slug = this.getSlug();
    const IsReceipt = this.data["report"] == "Receipts";
    let translate_filename = this.translateService.instant(this.data["report"]);
    let fileName = `${translate_filename}_${this.today}`;
    slug =
      this.dateFrom || this.dateTo
        ? IsReceipt
          ? `receipts_reports?user_id=${
              this.userId
            }&lang=${this.lang.toUpperCase()}&companyCheckAll=true&miscellaneous=true&view=false&date_from=${
              this.dateFrom
            }&date_to=${this.dateTo}`
          : slug +
            `?company_id=${this.userId}&date_from=${this.dateFrom}&date_to=${this.dateTo}`
        : slug + `?company_id=${this.userId}`;
    this.appService.openUrlWithToken(slug).subscribe((res) => {
      saveAs(res, fileName);
    });
  }

  downloadPdf() {
    const IsReceipt = this.data["report"] == "Receipts";
    let slug = this.getSlug();
    let translate_filename = this.translateService.instant(this.data["report"]);
    let fileName = `${translate_filename}_${this.today}`;
    slug =
      this.dateFrom || this.dateTo
        ? IsReceipt
          ? `receipts_reports?user_id=${
              this.userId
            }&lang=${this.lang.toUpperCase()}&companyCheckAll=true&miscellaneous=true&view=false&date_from=${
              this.dateFrom
            }&date_to=${this.dateTo}&pdf=true`
          : slug +
            `?company_id=${this.userId}&date_from=${this.dateFrom}&date_to=${this.dateTo}&pdf=true`
        : slug + `?company_id=${this.userId}&pdf=true`;
    this.appService.openUrlWithToken(slug).subscribe((res) => {
      saveAs(res, fileName);
    });
  }
  // ********** end download reports ********** //
}
