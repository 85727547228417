import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";

import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { map, takeUntil } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { AppService } from "../../../services/helpers/app.service";
import { EventsService } from "../../../services/helpers/events.service";
import { TranslateService } from "@ngx-translate/core";
import { FormatNumbersService } from "../../../services/helpers/format-numbers.service";
import { StoreService } from "../../../services/helpers/store.service";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  smsBalance: any = 0;
  userRole: any;
  userData: any = {
    address1: null,
    address2: null,
    city: null,
    country_id: null,
    email: null,
    full_name: "Full name",
    national_id: null,
    national_id_type: null,
    notification_tel: null,
    tel1: null,
    tel2: null,
    tel3: null,
  };
  isLoadingUserData = true;
  themes = [
    {
      value: "default",
      name: "Light",
    },
    {
      value: "dark",
      name: "Dark",
    },
    {
      value: "cosmic",
      name: "Cosmic",
    },
    {
      value: "corporate",
      name: "Corporate",
    },
  ];

  currentTheme = "default";

  userMenu = [];
  company_logo;
  messages: any;
  private itemClickSubscription: Subscription;
  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    private eventservice: EventsService,
    private tranlateService: TranslateService,
    private appservice: AppService,
    private storeService: StoreService,
    private formatNumber: FormatNumbersService
  ) {
    localStorage.getItem("user_type") == "EMPLOYEE"
      ? (this.userMenu = [
          { title: this.tranlateService.instant("Profile") },
          { title: this.tranlateService.instant("Change Password") },
          { title: this.tranlateService.instant("Company Settings") },
          { title: this.tranlateService.instant("Log out") },
        ])
      : (this.userMenu = [
          { title: this.tranlateService.instant("Profile") },
          { title: this.tranlateService.instant("Change Password") },
          { title: this.tranlateService.instant("Log out") },
        ]);
    this.eventservice.updateprofileObser.subscribe((res) => {
      this.getAuthenticatedUser();
    });
  }
  isSubMenuOpen = false;
  ngOnInit() {
    this.getBalance();
    this.getMessageCount();
    this.currentTheme = this.themeService.currentTheme;
    this.getAuthenticatedUser();
    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => {
        this.user = users.nick;
      });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));

    this.itemClickSubscription = this.menuService
      .onItemClick()
      .subscribe((event) => {
        this.onItemSelection(event.item.title);
        if (event.item.title == "Settings") {
          this.isSubMenuOpen = true;
        }
        // console.log(this.isSubMenuOpen);
      });
    // this.menuService.onItemHover().subscribe((event)=>{
    //   console.log(event);

    // })
    this.userRole = localStorage.getItem("user_type");
    this.storeService.currentCompanyLogo.subscribe((logo) => {
      this.company_logo = logo ? logo : localStorage.getItem("company_logo");
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.itemClickSubscription) {
      this.itemClickSubscription.unsubscribe();
    }
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  onItemSelection(title) {
    if (title === "Profile" || title === "الحساب") {
      this.router.navigate(["./pages/profile"]);
    } else if (title === "Log out" || title === "تسجيل الخروج") {
      this.logOut();
    } else if (title === "Change Password" || title === "تغيير كلمة السر") {
      this.router.navigate(["./pages/changePassword"]);
    } else if (title === "Company Settings" || title === "إعدادات الشركة") {
      this.router.navigate(["./pages/settings"]);
    }
  }
  logOut() {
    this.appservice
      .POST("logout", {
        Authorization: "Bearer " + localStorage.getItem("token"),
      })
      .subscribe((res) => {
        const rental_lang = localStorage.getItem("rental_lang");
        localStorage.clear();
        localStorage.setItem("rental_lang", rental_lang);
        this.appservice.user.next({});
        this.router.navigate(["auth/login"]).then(() => {
          //  window.location.reload()
        });
      });
  }
  getAuthenticatedUser() {
    const id = localStorage.getItem("userId");
    if (id !== null) {
      this.appservice.GET("users/" + id).subscribe(
        (res) => {
          this.userData = res.user;
          if (!this.userData?.logo) {
            this.userData.logo = "../../../../assets/images/new-logo.png";
          }
          this.isLoadingUserData = false;
        },
        (err) => {
          this.isLoadingUserData = false;
        }
      );
    }
  }
  getBalance() {
    let user_type = localStorage.getItem("user_type");
    if (
      !["TENANT", "OWNER", "LANDLORD"].includes(user_type) &&
      this.storeService.checkForAccess("get-balance")
    )
      this.appservice.GET("get-balance").subscribe((res) => {
        this.smsBalance = res.data;
      });
  }
  getMessageCount() {
    if (this.storeService.checkForAccess("sms-count")) {
      this.appservice
        .GET(`contact_us/countMessage/${localStorage.getItem("userId")}`)
        .subscribe((res) => {
          this.messages = res.data;
        });
    }
  }
  formatNum(num) {
    return this.formatNumber.formatNum(String(num));
  }
}
