import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { StoreService } from '../../services/helpers/store.service';

@Directive({
  selector: '[appHasPage]'
})
export class HasPageDirective {
  user_role = localStorage.getItem('user_type')
  @Input() appHasPage: string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: StoreService
  ) {}

  ngOnInit() {
    let embeddedViewCreated = false;

    if (!["TENANT", "OWNER", "LANDLORD"].includes(this.user_role)) {
      this.authService.currentRolePages.subscribe((userPermissions) => {
        const hasAccess = this.appHasPage.some(page => {
          return this.authService.checkForAccess(page);
        });
        if (hasAccess && !embeddedViewCreated) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          embeddedViewCreated = true;
        } else if (!hasAccess && embeddedViewCreated) {
          this.viewContainer.clear();
          embeddedViewCreated = false;
        }
      });
    } else {
      if (!embeddedViewCreated) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        embeddedViewCreated = true;
      }
    }
  }

}
