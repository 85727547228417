import { Component } from "@angular/core";

@Component({
  selector: "ngx-one-column-layout",
  styleUrls: ["./one-column.layout.scss"],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>
      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
      <ng-content select="nb-menu"></ng-content>
        <div routerLink="./dashboard/super" class="mt-5 d-flex flex-column align-items-center">
          <img
            class="logo-header w-50"
            src="assets/images/boyot-en-ar.png"
            alt=""
          />
          <nb-menu class="text-center" [items]="menu"> </nb-menu>
        </div>
      </nb-sidebar>

      <nb-layout-column>
        <ngx-breadcrumb></ngx-breadcrumb>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
   versionNumber = require("../../../../../package.json").version;
   version = "version ";
   menu=[
      {
    title: this.version + this.versionNumber,
  },
   ]
   constructor(){}
}
