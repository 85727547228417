<br>
<div class="container">
    <div class="card">
        <div class="card-header">
            <h5 class="c-logo-blue">Select your payment method </h5>
        </div>
        <div class="form-check pad">
            <input [(ngModel)]="payment_method" checked value="visa" class="form-check-input" type="radio"
                name="flexRadioDefault" id="flexRadioDefault1">
            <label class="form-check-label" for="flexRadioDefault1">
                Creditcard / Debitcard
            </label>
        </div>
        <div class="form-check pad">
            <input [(ngModel)]="payment_method" value="mastercard" class="form-check-input" type="radio"
                name="flexRadioDefault" id="flexRadioDefault2" disabled>
            <label class="form-check-label" for="flexRadioDefault2">
                Installments
            </label>
        </div>
        <div class="form-check pad">
            <input [(ngModel)]="payment_method" value="fawry" class="form-check-input" type="radio"
                name="flexRadioDefault" id="flexRadioDefault2" disabled>
            <label class="form-check-label" for="flexRadioDefault2">
                Fawry
            </label>
        </div>
        <div class="form-group pad row justify-content-center">
            <label class="label col-sm-2 col-form-label"></label>
            <div class="col-sm-6 flex-space-around flex-direction-c flex-direction-c">
                <button type="submit" class="save" (click)="submit()">{{'Save' |
                    translate}}</button>
            </div>
        </div>
    </div>
</div>
