import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-not-found',
  styleUrls: ['./not-found.component.scss'],
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent {

  userRole = localStorage.getItem('user_type');

  constructor(public router: Router) {
  }

  navigatoeToDashboard() {
    if (this.userRole == '2') {
      this.router.navigate(['/pages/dashboard/payer']);
    } else {
      this.router.navigate(['/pages/dashboard/super']);
    }
  }
}
