<div class="container" *ngIf="lang === 'en'">
  <ngx-chg-lang></ngx-chg-lang>
  <div class="row">
    <div class="col-12">
      <h1 class="heading"><strong> Our privacy policy</strong></h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ul>
        <li>
          our privacy policy describes the ways in which we collect, store, use
          and protect your personal information and it is important for you to
          review this privacy policy.
        </li>
        <li>
          By “personal information” we mean information that can be associated
          with a specific person and can be used to identify that person.
        </li>
        <li>
          We do not consider anonymized information to constitute personal
          information, as it cannot be used to identify a specific person.
        </li>
        <li>
          You accept this Privacy Policy when you sign up for, access, or use
          our products, services, content, features, technologies or functions
          offered on our website and all related sites, applications, and
          services (collectively “Boyot Services”).
        </li>
        <li>
          By providing us with your personal information you expressly consent
          to us processing your personal information in accordance with the
          terms of our privacy policy.
        </li>
        <li>
          We may amend our privacy policy at any time by posting a revised
          version on www.boyot.net The revised version will be effective at the
          time we post it and, following such posting, your continued use of
          www.boyot.net, your boyot account and the related boyot tools and
          services (collectively the “boyot Service”) shall constitute your
          express consent to us continuing to process your personal information
          in accordance with the terms of our revised privacy policy.
        </li>
        <li>
          We would encourage you to check www.boyot.net regularly for the most
          up- to - date version of our privacy policy.
        </li>
        <li>
          If we are going to use your personal information in any way other than
          the way set out in our privacy policy or any from time to time
          amendments, we will notify you via email and you will have the choice
          of whether or not to allow us to use your personal information in such
          a way.
        </li>
        <li>
          Our privacy policy will cover the following topics: our collection of
          your personal information, our use of your personal information
          accessing, reviewing and amending your personal information, our
          communications with you and third party website links cookies
          competitions protecting your personal information.
        </li>
        <li>
          Our Collection of your personal Information in order to open your
          Account and start using the boyot Service, you will be asked to
          provide us with certain personal information, such as your name, phone
          number, email address and other identifying information which we may
          combine with your personal information.
        </li>
        <li>
          Additionally, in order for us to verify your identity we may need to
          request from your valid proof of identification.
        </li>
        <li>
          You should ensure that the email address you have registered with us
          is always up- to - date so that we can always contact you with
          important information regarding your Account and the boyot Service.
        </li>
        <li>
          We may also need to collect selected financial information from you,
          such as bank account details.
        </li>
        <li>
          We may offer you the ability to connect with boyot Services using a
          mobile device, either through a mobile application or via a mobile
          optimized website.
        </li>
        <li>
          The provisions of this Privacy Policy apply to all such mobile access
          and use of mobile devices.
        </li>
        <li>
          boyot may monitor or record its telephone conversations with you or
          anyone acting on your behalf.
        </li>
        <li>
          By communicating with boyot, you acknowledge that your communication
          may be overheard, monitored, or recorded without further notice or
          warning.
        </li>
        <li>
          Finally, we may collect additional information from or about you in
          other ways not specifically described here.
        </li>
        <li>
          For example, we may collect information related to your contact with
          our customer support team or store the results when you respond to a
          survey.
        </li>
        <li>
          Our use of your personal information We use your personal information
          to provide and improve boyot Service to you.
        </li>
        <li>
          We may also use your personal information to prevent illegal
          activities and implement our User Agreement with you.
        </li>
        <li>
          Though we make every effort to preserve your privacy, we may need to
          disclose your personal information to law enforcement agencies,
          government agencies or other third parties where we are compelled to
          by court order or similar legal procedure; where we are required so to
          do to comply with law; where we are cooperating with an ongoing law
          enforcement investigation; or where we have a good faith belief that
          such action is necessary to prevent physical harm or financial loss,
          to report suspected illegal activity or to investigate a possible
          violation of our User Agreement.
        </li>
        <li>
          In the event of a sale of Boyot, its subsidiaries or affiliates or any
          assets of the Boyot business, your personal information may be
          disclosed to a potential purchaser for the purposes of the continued
          provision to you of the Boyot Service or otherwise in relation to the
          sale of Boyot, its subsidiaries or affiliates or any assets of the
          Boyot business.
        </li>
        <li>
          We may share your personal information with other unaffiliated third
          parties, for the following purposes:

          <ul>
            <li>
              Fraud Prevention and Risk Management: to help prevent fraud or
              assess and manage risk.
            </li>
            <li>
              Customer Service: for customer service purposes, including to help
              service your accounts or resolve disputes (e.g., billing or
              transactional).
            </li>
            <li>
              Legal Compliance: to help them comply with anti - money laundering
              and counter- terrorist financing verification requirements.
            </li>
            <li>
              Other third parties with your consent or direction to do so.
            </li>
          </ul>

          Otherwise, we will only disclose your personal information to a third
          party with your permission. We do not sell or rent any of your
          personal information to third parties in the –normal course of doing
          business and will only share your personal information with third
          parties in accordance with this privacy policy. Accessing, reviewing
          and amending your personal information You can access and review your
          information under “Profile” or “Account” when you log into your
          Account on www.boyot.net If your personal information changes in any
          way, if it is incorrectly presented in your Customer Account or if you
          wish to amend it for any other reason, you should promptly update,
          amend or correct your personal information (as applicable) via
          www.boyot.net additionally, in order to deactivate your Account, you
          will need to contact our customer support team via www.boyot.net or
          via phone number. Following the deactivation of your Account we may
          need to retain your personal information to comply with law, for
          technical troubleshooting requirements, to prevent fraud, assist in
          any legal investigations and take any other actions otherwise
          permitted by law. Our Communications with you Upon registering your
          Account, you will receive an email confirming your successful
          registration. Once registered, you may occasionally receive
          information on products, services and special deals from us. Third
          Party Website links www.boyot.net may contain links to other websites.
          boyot has no control over the practices of these other websites and is
          not responsible for the privacy practices of such other websites.
        </li>
        <li>
          We encourage you, when you leave www.boyot.net, to read the privacy
          statements of each and every website you visit if you intend to
          provide personal information to such website.
        </li>
        <li>
          boyot is not responsible for the content or information practices of
          such third parties.
        </li>
        <li>
          Protecting your personal information We hold your personal information
          on servers located in different countries.
        </li>
        <li>
          If you choose to provide us with personal information, you consent to
          the transfer of your personal information to, and the storage of that
          personal information on our servers. We take reasonable measures to
          safeguard all your personal information from unauthorized access, use
          or disclosure. However, the Internet is not a secure medium and we
          cannot guarantee the privacy of your personal information. You must
          enter your username and password each time you want to use the boyot
          Service. Never share your username and password or any details about
          your Account with anyone.
        </li>
        <li>
          If you are concerned that your username, password or Account has been
          compromised, please contact our customer support team immediately.
        </li>
        <li>
          In particular, if you are concerned that your password has been
          compromised, please ensure you change your password immediately by
          logging onto www.boyot.net You are responsible for maintaining
          adequate security and control of your boyot Account, email, mobile
          phone, any and all IDs, passwords, or any other codes that you use to
          access boyot Services.
        </li>
        <li>
          You agree to immediately notify us of any unauthorized use of your
          login credentials or other breach of security, either by phone or by
          e- mail mentioned on www.boyot.net All Transactions are processed by
          automated methods, and anyone who obtains your access credentials and/
          or mobile phone could use it your Account without your permission.
        </li>
        <li>
          If you furnish your mobile phone and / or email and / or your boyot
          Services access credentials to a third party, and the third party uses
          your login credentials, or you negligently make your mobile phone or
          access credentials available to a third party who then uses them, you
          will be liable for the Transactions conducted and other use or misuse
          of your boyot Account until and unless boyot has been notified
          otherwise.
        </li>
        <li>
          You are responsible for any use or misuse with respect to your login
          credentials.
        </li>
        <li>
          We may require you to close your boyot Account and open a new one, and
          / or change your boyot Services access credentials. Protecting your
          personal information How do we use Cookie? When you access our website
          or use boyot Services, we (including companies we work with) may place
          small data files on your computer or device. These data files may be
          cookies, pixel tags, “Flash cookies, ” or other local storage provided
          by your browser or associated applications(collectively “Cookies”).We
          use these technologies to; recognize you as a customer or visitor to
          our website; customize boyot Services, content, and advertising to
          cater your needs; To measure promotional effectiveness; help ensure
          that your account security is not compromised; mitigate risk and
          preventing fraud; and to promote trust and safety across our sites and
          boyot Services.
        </li>
        <li>
          We use both session and persistent cookies. Session cookies expire and
          no longer have any effect when you log out of your account or close
          your browser. Persistent cookies remain on your browser until you
          erase them or they expire. Can I Delete Cookie? boyot cookies are in
          your best interest to promote trust and safety across our sites and
          boyot Services. If you still intend to disable our cookies please feel
          free to refer help section of your browser, browser extensions, or
          installed applications for instructions on blocking, deleting, or
          disabling Cookies.
        </li>
        <li>
          Email un subscription for marketing and promotional emails You can
          easily unsubscribe from most types of email boyot sends you, but there
          are a few exceptions.
        </li>
        <li>
          The only types of email you can’t opt out of contain important
          information that we must make sure you know about. You can’t
          unsubscribe from administrative announcements. These include:

          <ul class="dashed">
            <li>Notifications of terms of service changes</li>
            <li>Notifications of privacy policy changes</li>
            <li>
              Welcome messages you receive when you sign up for a boyot product
              or service
            </li>
            <li>Billing statements</li>
            <li>Notifications about a discontinued service</li>
            <li>Updates on service outages</li>
            <li>
              Notifications related to account security, maintenance and other
              information deemed necessary to properly manage your customer
              account including its security and status
            </li>
            <li>Legal and dispute notifications</li>
          </ul>
        </li>
        <li>
          You can unsubscribe from all other types of email. These include:
          <ul class="dashed">
            <li>Marketing messages</li>
            <li>Newsletters</li>
            <li>Deals/Promotional emails</li>
          </ul>
        </li>
      </ul>

      <p>
        You can unsubscribe when you open your boyot account then click on
        profile then uncheck the box “Subscribe me to the boyot newsletter” then
        click confirm.
      </p>
    </div>
  </div>
</div>

<!-- Arabic Version -->

<div class="container ar" *ngIf="lang === 'ar'">
  <ngx-chg-lang></ngx-chg-lang>
  <div class="row">
    <div class="col-12">
      <h1 class="text-center"><strong>الشروط و الأحكام</strong></h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h2 class="text-center">
        <strong>
          بيووت للبرامج الإلكترونية شركةّ ذات<br />مسئولية محدودة وهي منصة
          إلكترونية<br />لإدارة خدمات و مدفوعات الوحدات االعقارية</strong
        >
      </h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p>
        بالضغط علي زر " ادفع الان " أنت توافق على دفع مصاريف الخدمة المعلنة
        بالصفحة الخاصة بالمدفوعات نظير استخدام خدمات شركة بيووت
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ul>
        <li>
          يصرح المستأجر لشركة بيووت للبرامج الإلكترونية ش.م.م. بتسليم إيجاره نيابة
          عنه إلى المالك.
        </li>
        <li>يتعهد بيووت بالسرية نيابة عن معلومات الملاك والمستأجرين.</li>
        <li>
          المستأجر مسئول مسئولية كاملة عن تقديم جميع تفاصيل معلومات المالك بشكل
          صحيح.
        </li>
        <li>أقر وأوافق على أنني المستأجر الخاص بهذا المالك تحديدا.</li>
        <li>
          المستأجر مسئول مسئولية كاملة عن تقديم تاريخ بدء التحصيل في الشهر
          المحدد.
        </li>
        <li>
          المستأجر مسئول مسئولية كاملة عن تقديم التفاصيل المصرفية الصحيحة لمحصِّل
          المالك الرسمي.
        </li>
        <li>
          سيتم فرض رسوم على المستأجر مقابل استخدام هذه الخدمة فيما يتعلق بخيار
          الدفع الذي تختاره.
        </li>
        <li>
          سيتم إيداع الإيجار في حساب المالك في غضون يومي عمل بعد نجاح المعاملة ،
          لمستخدمي الخدمة.
        </li>
        <li>
          سيتم إيداع الإيجار في حساب المالك في غضون يومي عمل بعد نجاح المعاملة ،
          لمستخدمي بطاقة الائتمان.
        </li>
        <li>يتم قبول جميع بطاقات الائتمان الصادرة عن جميع البنوك.</li>
        <li>
          يُطلب من المستأجر/الدافع الذي يستخدم الخدمة أن يكون متاحًا على أرقام
          الاتصال الخاصة به التي قدمها لنا.
        </li>
        <li>
          يجب أن تنتمي بطاقات الائتمان, وبطاقات الخصم المباشر ,و فوري باي,
          وميزة, والمحافظ المستخدمة في الدفع من خلال بوابة الدفع عبر الإنترنت في
          الوصول إلى المستأجر/الدافع. خلافًا لذلك ، يجب على المستأجر/الدافع
          الحصول على الإذن القانوني من مالك البطاقة لإجراء المعاملة.
        </li>
        <li>
          المستأجر/الدافع مسئول بالكامل عن دفع الفاتورة باستخدام بطاقات الائتمان
          وبطاقات الخصم المباشر والدفع الفوري والميزة والمحافظ بعد قراءة جميع
          الشروط والأحكام بعناية.
        </li>
        <li>يسري طلب استرداد الأموال قبل تحويل الإيجار إلى المالك فقط.</li>
        <li>سيتم فرض رسوم على أي طلبات استرداد.</li>
        <li>
          لإيقاف هذه الخدمة ، يمكنك الاتصال بنا على
          <a href="mailto:'support@boyot.net'">support@boyot.net</a>
        </li>
        <li>
          للاستفسارات بعد الدفع أو طلب الاسترداد ، اتصل على
          <a href="mailto:'support@boyot.net'">support@boyot.net</a>
        </li>
      </ul>
    </div>
  </div>
</div>
