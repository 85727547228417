import { Injectable } from '@angular/core';
import { SmartTableData } from '../data/smart-table';

@Injectable()
export class SmartTableService extends SmartTableData {
  data = [
    {
      id: 1,
      firstName: 'Mark',
      lastName: 'Otto',
      username: '@mdo',
      email: 'mdo@gmail.com',
      age: '28',
    },
    {
      id: 2,
      firstName: 'Jacob',
      lastName: 'Thornton',
      username: '@fat',
      email: 'fat@yandex.ru',
      age: '45',
    },
    {
      id: 3,
      firstName: 'Larry',
      lastName: 'Bird',
      username: '@twitter',
      email: 'twitter@outlook.com',
      age: '18',
    },
    {
      id: 4,
      firstName: 'John',
      lastName: 'Snow',
      username: '@snow',
      email: 'snow@gmail.com',
      age: '20',
    },
    {
      id: 5,
      firstName: 'Jack',
      lastName: 'Sparrow',
      username: '@jack',
      email: 'jack@yandex.ru',
      age: '30',
    },
    {
      id: 6,
      firstName: 'Ann',
      lastName: 'Smith',
      username: '@ann',
      email: 'ann@gmail.com',
      age: '21',
    },
    {
      id: 7,
      firstName: 'Barbara',
      lastName: 'Black',
      username: '@barbara',
      email: 'barbara@yandex.ru',
      age: '43',
    },
    {
      id: 8,
      firstName: 'Sevan',
      lastName: 'Bagrat',
      username: '@sevan',
      email: 'sevan@outlook.com',
      age: '13',
    },
    {
      id: 9,
      firstName: 'Ruben',
      lastName: 'Vardan',
      username: '@ruben',
      email: 'ruben@gmail.com',
      age: '22',
    },
    {
      id: 10,
      firstName: 'Karen',
      lastName: 'Sevan',
      username: '@karen',
      email: 'karen@yandex.ru',
      age: '33',
    },
    {
      id: 11,
      firstName: 'Mark',
      lastName: 'Otto',
      username: '@mark',
      email: 'mark@gmail.com',
      age: '38',
    },
    {
      id: 12,
      firstName: 'Jacob',
      lastName: 'Thornton',
      username: '@jacob',
      email: 'jacob@yandex.ru',
      age: '48',
    },
    {
      id: 13,
      firstName: 'Haik',
      lastName: 'Hakob',
      username: '@haik',
      email: 'haik@outlook.com',
      age: '48',
    },
    {
      id: 14,
      firstName: 'Garegin',
      lastName: 'Jirair',
      username: '@garegin',
      email: 'garegin@gmail.com',
      age: '40',
    },
    {
      id: 15,
      firstName: 'Krikor',
      lastName: 'Bedros',
      username: '@krikor',
      email: 'krikor@yandex.ru',
      age: '32',
    },
    {
      id: 16,
      firstName: 'Francisca',
      lastName: 'Brady',
      username: '@Gibson',
      email: 'franciscagibson@comtours.com',
      age: 11,
    },
    {
      id: 17,
      firstName: 'Tillman',
      lastName: 'Figueroa',
      username: '@Snow',
      email: 'tillmansnow@comtours.com',
      age: 34,
    },
    {
      id: 18,
      firstName: 'Jimenez',
      lastName: 'Morris',
      username: '@Bryant',
      email: 'jimenezbryant@comtours.com',
      age: 45,
    },
    {
      id: 19,
      firstName: 'Sandoval',
      lastName: 'Jacobson',
      username: '@Mcbride',
      email: 'sandovalmcbride@comtours.com',
      age: 32,
    },
    {
      id: 20,
      firstName: 'Griffin',
      lastName: 'Torres',
      username: '@Charles',
      email: 'griffincharles@comtours.com',
      age: 19,
    },
    {
      id: 21,
      firstName: 'Cora',
      lastName: 'Parker',
      username: '@Caldwell',
      email: 'coracaldwell@comtours.com',
      age: 27,
    },
    {
      id: 22,
      firstName: 'Cindy',
      lastName: 'Bond',
      username: '@Velez',
      email: 'cindyvelez@comtours.com',
      age: 24,
    },
    {
      id: 23,
      firstName: 'Frieda',
      lastName: 'Tyson',
      username: '@Craig',
      email: 'friedacraig@comtours.com',
      age: 45,
    },
    {
      id: 24,
      firstName: 'Cote',
      lastName: 'Holcomb',
      username: '@Rowe',
      email: 'coterowe@comtours.com',
      age: 20,
    },
    {
      id: 25,
      firstName: 'Trujillo',
      lastName: 'Mejia',
      username: '@Valenzuela',
      email: 'trujillovalenzuela@comtours.com',
      age: 16,
    },
    {
      id: 26,
      firstName: 'Pruitt',
      lastName: 'Shepard',
      username: '@Sloan',
      email: 'pruittsloan@comtours.com',
      age: 44,
    },
    {
      id: 27,
      firstName: 'Sutton',
      lastName: 'Ortega',
      username: '@Black',
      email: 'suttonblack@comtours.com',
      age: 42,
    },
    {
      id: 28,
      firstName: 'Marion',
      lastName: 'Heath',
      username: '@Espinoza',
      email: 'marionespinoza@comtours.com',
      age: 47,
    },
    {
      id: 29,
      firstName: 'Newman',
      lastName: 'Hicks',
      username: '@Keith',
      email: 'newmankeith@comtours.com',
      age: 15,
    },
    {
      id: 30,
      firstName: 'Boyle',
      lastName: 'Larson',
      username: '@Summers',
      email: 'boylesummers@comtours.com',
      age: 32,
    },
    {
      id: 31,
      firstName: 'Haynes',
      lastName: 'Vinson',
      username: '@Mckenzie',
      email: 'haynesmckenzie@comtours.com',
      age: 15,
    },
    {
      id: 32,
      firstName: 'Miller',
      lastName: 'Acosta',
      username: '@Young',
      email: 'milleryoung@comtours.com',
      age: 55,
    },
    {
      id: 33,
      firstName: 'Johnston',
      lastName: 'Brown',
      username: '@Knight',
      email: 'johnstonknight@comtours.com',
      age: 29,
    },
    {
      id: 34,
      firstName: 'Lena',
      lastName: 'Pitts',
      username: '@Forbes',
      email: 'lenaforbes@comtours.com',
      age: 25,
    },
    {
      id: 35,
      firstName: 'Terrie',
      lastName: 'Kennedy',
      username: '@Branch',
      email: 'terriebranch@comtours.com',
      age: 37,
    },
    {
      id: 36,
      firstName: 'Louise',
      lastName: 'Aguirre',
      username: '@Kirby',
      email: 'louisekirby@comtours.com',
      age: 44,
    },
    {
      id: 37,
      firstName: 'David',
      lastName: 'Patton',
      username: '@Sanders',
      email: 'davidsanders@comtours.com',
      age: 26,
    },
    {
      id: 38,
      firstName: 'Holden',
      lastName: 'Barlow',
      username: '@Mckinney',
      email: 'holdenmckinney@comtours.com',
      age: 11,
    },
    {
      id: 39,
      firstName: 'Baker',
      lastName: 'Rivera',
      username: '@Montoya',
      email: 'bakermontoya@comtours.com',
      age: 47,
    },
    {
      id: 40,
      firstName: 'Belinda',
      lastName: 'Lloyd',
      username: '@Calderon',
      email: 'belindacalderon@comtours.com',
      age: 21,
    },
    {
      id: 41,
      firstName: 'Pearson',
      lastName: 'Patrick',
      username: '@Clements',
      email: 'pearsonclements@comtours.com',
      age: 42,
    },
    {
      id: 42,
      firstName: 'Alyce',
      lastName: 'Mckee',
      username: '@Daugherty',
      email: 'alycedaugherty@comtours.com',
      age: 55,
    },
    {
      id: 43,
      firstName: 'Valencia',
      lastName: 'Spence',
      username: '@Olsen',
      email: 'valenciaolsen@comtours.com',
      age: 20,
    },
    {
      id: 44,
      firstName: 'Leach',
      lastName: 'Holcomb',
      username: '@Humphrey',
      email: 'leachhumphrey@comtours.com',
      age: 28,
    },
    {
      id: 45,
      firstName: 'Moss',
      lastName: 'Baxter',
      username: '@Fitzpatrick',
      email: 'mossfitzpatrick@comtours.com',
      age: 51,
    },
    {
      id: 46,
      firstName: 'Jeanne',
      lastName: 'Cooke',
      username: '@Ward',
      email: 'jeanneward@comtours.com',
      age: 59,
    },
    {
      id: 47,
      firstName: 'Wilma',
      lastName: 'Briggs',
      username: '@Kidd',
      email: 'wilmakidd@comtours.com',
      age: 53,
    },
    {
      id: 48,
      firstName: 'Beatrice',
      lastName: 'Perry',
      username: '@Gilbert',
      email: 'beatricegilbert@comtours.com',
      age: 39,
    },
    {
      id: 49,
      firstName: 'Whitaker',
      lastName: 'Hyde',
      username: '@Mcdonald',
      email: 'whitakermcdonald@comtours.com',
      age: 35,
    },
    {
      id: 50,
      firstName: 'Rebekah',
      lastName: 'Duran',
      username: '@Gross',
      email: 'rebekahgross@comtours.com',
      age: 40,
    },
    {
      id: 51,
      firstName: 'Earline',
      lastName: 'Mayer',
      username: '@Woodward',
      email: 'earlinewoodward@comtours.com',
      age: 52,
    },
    {
      id: 52,
      firstName: 'Moran',
      lastName: 'Baxter',
      username: '@Johns',
      email: 'moranjohns@comtours.com',
      age: 20,
    },
    {
      id: 53,
      firstName: 'Nanette',
      lastName: 'Hubbard',
      username: '@Cooke',
      email: 'nanettecooke@comtours.com',
      age: 55,
    },
    {
      id: 54,
      firstName: 'Dalton',
      lastName: 'Walker',
      username: '@Hendricks',
      email: 'daltonhendricks@comtours.com',
      age: 25,
    },
    {
      id: 55,
      firstName: 'Bennett',
      lastName: 'Blake',
      username: '@Pena',
      email: 'bennettpena@comtours.com',
      age: 13,
    },
    {
      id: 56,
      firstName: 'Kellie',
      lastName: 'Horton',
      username: '@Weiss',
      email: 'kellieweiss@comtours.com',
      age: 48,
    },
    {
      id: 57,
      firstName: 'Hobbs',
      lastName: 'Talley',
      username: '@Sanford',
      email: 'hobbssanford@comtours.com',
      age: 28,
    },
    {
      id: 58,
      firstName: 'Mcguire',
      lastName: 'Donaldson',
      username: '@Roman',
      email: 'mcguireroman@comtours.com',
      age: 38,
    },
    {
      id: 59,
      firstName: 'Rodriquez',
      lastName: 'Saunders',
      username: '@Harper',
      email: 'rodriquezharper@comtours.com',
      age: 20,
    },
    {
      id: 60,
      firstName: 'Lou',
      lastName: 'Conner',
      username: '@Sanchez',
      email: 'lousanchez@comtours.com',
      age: 16,
    },
  ];
  propertiesData = [
    {
      id: 1,
      property_name: 'Almadina Tower 1',
      area: 'giza',
      units: '60',
      online_collected: '223000',
      offline_collected: '85000',
      total_pending: '1500000',
      active: true,
    },
    {
      id: 2,
      property_name: 'Almadina Tower 2',
      area: 'mohandseen',
      units: '50',
      online_collected: '323000',
      offline_collected: '65000',
      total_pending: '2500000',
      active: true,
    },
    {
      id: 3,
      property_name: 'Almadina Tower 3',
      area: 'madenty',
      units: '40',
      online_collected: '423000',
      offline_collected: '35000',
      total_pending: '3500000',
      active: false,
    },
    {
      id: 4,
      property_name: 'Almadina Tower 4',
      area: 'naser city',
      units: '500',
      online_collected: '523000',
      offline_collected: '25000',
      total_pending: '4500000',
      active: true,
    },
    {
      id: 5,
      property_name: 'Almadina Tower 5',
      area: 'maadi',
      units: '70',
      online_collected: '523000',
      offline_collected: '445000',
      total_pending: '5500000',
      active: true,
    },
    {
      id: 6,
      property_name: 'Almadina Tower 6',
      area: '530',
      units: '80',
      online_collected: '723000',
      offline_collected: '345000',
      total_pending: '6500000',
      active: false,
    },
    // {
    //   id: 7,
    //   property_name: "Almadina Tower 7",
    //   area: "october",
    //   units: "90",
    //   online_collected: "823000",
    //   offline_collected: "245000",
    //   total_pending: "7500000",
    //   active: false,
    // },
    // {
    //   id: 8,
    //   property_name: "Almadina Tower 8",
    //   area: "smoha",
    //   units: "110",
    //   online_collected: "923000",
    //   offline_collected: "145000",
    //   total_pending: "8500000",
    //   active: false,
    // },
    // {
    //   id: 9,
    //   property_name: "Almadina Tower 9",
    //   area: "zahraa maadi",
    //   units: "220",
    //   online_collected: "123000",
    //   offline_collected: "945000",
    //   total_pending: "9500000",
    //   active: true,
    // },
    // {
    //   id: 10,
    //   property_name: "Almadina Tower 10",
    //   area: "sheikh zaid",
    //   units: "330",
    //   online_collected: "24000",
    //   offline_collected: "845000",
    //   total_pending: "11500000",
    //   active: true,
    // },
  ];
  leasesData = [
    {
      id: 1,
      property_name: 'Almadina Tower 1',
      zone: 'giza',
      tenant_name: 'khaled elrifaay',
      status: 'Paid',
      remaining_amount: '0',
      collected: '1500000',
      type: 'Rent',
      active: 'active',
    },
    {
      id: 2,
      property_name: 'Almadina Tower 2',
      zone: 'mohandseen',
      tenant_name: 'ahmed said',
      status: 'Paid',
      remaining_amount: '0',
      collected: '2500000',
      type: 'Rent',
      active: 'active',
    },
    {
      id: 3,
      property_name: 'Almadina Tower 3',
      zone: 'madenty',
      tenant_name: 'mahmoud ashour',
      status: 'Past due',
      remaining_amount: '35000',
      collected: '3500000',
      type: 'Rent',
      active: 'archived',
    },
    {
      id: 4,
      property_name: 'Almadina Tower 4',
      zone: 'naser city',
      tenant_name: 'mohamed ibrahim',
      status: 'Paid',
      remaining_amount: '0',
      collected: '4500000',
      type: 'Service',
      active: 'active',
    },
    {
      id: 5,
      property_name: 'Almadina Tower 5',
      zone: 'maadi',
      tenant_name: 'mokhtar ismaal',
      status: 'Past Due',
      remaining_amount: '445000',
      collected: '5500000',
      type: 'Service',
      active: 'active',
    },
    {
      id: 6,
      property_name: 'Almadina Tower 6',
      zone: '530',
      tenant_name: 'hend ibrahim',
      status: 'Paid',
      remaining_amount: '0',
      collected: '6500000',
      type: 'Rent',
      active: 'archived',
    },
    {
      id: 7,
      property_name: 'Almadina Tower 7',
      zone: 'october',
      tenant_name: 'karim mahmoud',
      status: 'Past due',
      remaining_amount: '245000',
      collected: '7500000',
      type: 'Service',
      active: 'active',
    },
    {
      id: 8,
      property_name: 'Almadina Tower 8',
      zone: 'smoha',
      tenant_name: 'ahmed karim',
      status: 'Past due',
      remaining_amount: '145000',
      collected: '8500000',
      type: 'Rent',
      active: 'active',
    },
    {
      id: 9,
      property_name: 'Almadina Tower 9',
      zone: 'zahraa maadi',
      tenant_name: 'hamdy mohamed',
      status: 'Paid',
      remaining_amount: '0',
      collected: '9500000',
      type: 'Rent',
      active: 'active',
    },
    {
      id: 10,
      property_name: 'Almadina Tower 10',
      zone: 'sheikh zaid',
      tenant_name: 'mai ahmed',
      status: 'Paid',
      remaining_amount: '0',
      collected: '11500000',
      type: 'Rent',
      active: 'active',
    },
    {
      id: 11,
      property_name: 'Almadina Tower 11',
      zone: 'aswaan',
      tenant_name: 'ibraim mazin',
      status: 'Paid',
      remaining_amount: '0',
      collected: '500000',
      type: 'Rent',
      active: 'active',
    },
    {
      id: 12,
      property_name: 'Almadina Tower 12',
      zone: 'fayoom',
      tenant_name: 'ahmed soliman',
      status: 'Past due',
      remaining_amount: '645000',
      collected: '510000',
      type: 'Rent',
      active: 'active',
    },
    {
      id: 13,
      property_name: 'Almadina Tower 13',
      zone: 'zamalek',
      tenant_name: 'mariam mohamed',
      status: 'Paid',
      remaining_amount: '0',
      collected: '2200000',
      type: 'Rent',
      active: 'active',
    },
  ];
  invoicesDate = [
    {
      id: 1,
      invoice_ref: 'INV_523202211',
      property_name: 'Almadina Tower 1',
      zone: 'October',
      tenant_name: 'Hend Ahmed',
      date_from: '1/8/2020',
      date_to: '1/8/2029',
      status: 'Upcoming',
      total_price: '650000',
      type: 'Rent',
    },
    {
      id: 2,
      invoice_ref: 'INV_5232102211',
      property_name: 'Almadina Tower 2',
      zone: 'giza',
      tenant_name: 'Ahmed ibrahim',
      date_from: '2/2/2020',
      date_to: '2//2021',
      status: 'Upcoming',
      total_price: '900000',
      type: 'Service',
    },
    {
      id: 3,
      invoice_ref: 'INV_5224352211',
      property_name: 'Almadina Tower 3',
      zone: 'giza',
      tenant_name: 'khaled elrifaay',
      date_from: '14/4/2020',
      date_to: '14/4/2026',
      status: 'Upcoming',
      total_price: '80000',
      type: 'Rent',
    },
    {
      id: 4,
      invoice_ref: 'INV_5221002211',
      property_name: 'Almadina Tower 1',
      zone: 'giza',
      tenant_name: 'Mai zidan',
      date_from: '1/1/2020',
      date_to: '1/1/2025',
      status: 'Upcoming',
      total_price: '600000',
      type: 'Rent',
    },
    {
      id: 4,
      invoice_ref: 'INV_5221002211',
      property_name: 'Almadina Tower 1',
      zone: 'giza',
      tenant_name: 'Mai zidan',
      date_from: '1/1/2020',
      date_to: '1/1/2025',
      status: 'Upcoming',
      total_price: '600000',
      type: 'Rent',
    },
    {
      id: 4,
      invoice_ref: 'INV_5221002211',
      property_name: 'Almadina Tower 1',
      zone: 'giza',
      tenant_name: 'Mai zidan',
      date_from: '1/1/2020',
      date_to: '1/1/2025',
      status: 'Upcoming',
      total_price: '600000',
      type: 'Rent',
    },
    {
      id: 4,
      invoice_ref: 'INV_5221002211',
      property_name: 'Almadina Tower 1',
      zone: 'giza',
      tenant_name: 'Mai zidan',
      date_from: '1/1/2020',
      date_to: '1/1/2025',
      status: 'Upcoming',
      total_price: '600000',
      type: 'Rent',
    },
  ];
  getData() {
    return this.data;
  }
  getPropertyData() {
    return this.propertiesData;
  }
  getLeases() {
    return this.leasesData;
  }
  getInvoices() {
    return this.invoicesDate;
  }
}
