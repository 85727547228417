import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-payment-messages',
  templateUrl: './payment-messages.component.html',
  styleUrls: ['./payment-messages.component.scss']
})
export class PaymentMessagesComponent implements OnInit {
  receiptId:any
  constructor(  
    private avRouter: ActivatedRoute,
    private router: Router,
    private translateService:TranslateService) {
      this.translateService.use('ar')
      this.receiptId = this.avRouter.snapshot.params["receiptId"]
     }

  ngOnInit(): void {
  }
  receipt(){
    this.router.navigate([`/receipt/${this.receiptId}`])
  }
}
